import * as StackBlur from 'stackblur-canvas'

export const applyBlurToVideo = async (file: File): Promise<File> => {
  const bodyPix = await import('@tensorflow-models/body-pix')
  const net = await bodyPix.load()

  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.src = URL.createObjectURL(file)
    video.muted = false

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const canvasStream = canvas.captureStream()

    let mediaRecorder: MediaRecorder
    const chunks: BlobPart[] = []

    video.onloadedmetadata = async () => {
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      // Combine canvas stream with the original audio
      const audioContext = new AudioContext()
      const sourceNode = audioContext.createMediaElementSource(video)
      const destination = audioContext.createMediaStreamDestination()
      sourceNode.connect(destination)

      const audioTracks = destination.stream.getAudioTracks()
      const videoTracks = canvasStream.getVideoTracks()

      const combinedStream = new MediaStream([...videoTracks, ...audioTracks])

      mediaRecorder = new MediaRecorder(combinedStream)
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data)
        }
      }

      mediaRecorder.onstop = () => {
        const blurredBlob = new Blob(chunks, {type: 'video/mp4'})
        const blurredFile = new File([blurredBlob], `background_blurred_${file.name}`, {
          type: 'video/mp4',
        })
        resolve(blurredFile)
      }

      video.play()
      mediaRecorder.start()

      const renderFrame = async () => {
        // Segment the person
        const segmentation = await net.segmentPerson(video, {
          internalResolution: 'medium',
          segmentationThreshold: 0.7,
        })

        // Draw the video frame
        ctx!.drawImage(video, 0, 0, canvas.width, canvas.height)

        // Create a blurred background
        const imageData = ctx!.getImageData(0, 0, canvas.width, canvas.height)
        const blurredCanvas = document.createElement('canvas')
        const blurredCtx = blurredCanvas.getContext('2d')!
        blurredCanvas.width = canvas.width
        blurredCanvas.height = canvas.height
        blurredCtx.putImageData(imageData, 0, 0)

        // Apply blur to the entire blurredCanvas
        StackBlur.canvasRGBA(
          blurredCanvas,
          0,
          0,
          canvas.width,
          canvas.height,
          20 // Adjust blur radius as needed
        )

        // Get blurred image data
        const blurredImageData = blurredCtx.getImageData(0, 0, canvas.width, canvas.height)
        const combinedImageData = ctx!.getImageData(0, 0, canvas.width, canvas.height)

        // Replace background pixels with blurred ones
        for (let i = 0; i < segmentation.data.length; i++) {
          if (segmentation.data[i] <= 0.5) {
            // Background pixel
            const pixelIndex = i * 4
            combinedImageData.data[pixelIndex] = blurredImageData.data[pixelIndex]
            combinedImageData.data[pixelIndex + 1] = blurredImageData.data[pixelIndex + 1]
            combinedImageData.data[pixelIndex + 2] = blurredImageData.data[pixelIndex + 2]
          }
        }

        // Put the combined image back on the canvas
        ctx!.putImageData(combinedImageData, 0, 0)

        // Continue rendering frames until the video ends
        if (!video.ended) {
          requestAnimationFrame(renderFrame)
        } else {
          mediaRecorder.stop()
        }
      }

      renderFrame()
    }

    video.onerror = (error) => {
      reject(new Error('Error processing video: ' + error))
    }
  })
}

export const applyImageBackgroundToVideo = async (
  file: File,
  backgroundImageUrl: string // URL of the background image
): Promise<File> => {
  const bodyPix = await import('@tensorflow-models/body-pix')
  const net = await bodyPix.load()

  // Preload the background image
  const loadBackgroundImage = (): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.crossOrigin = 'anonymous' // Required if loading from different domain
      img.onload = () => resolve(img)
      img.onerror = () => reject(new Error('Failed to load background image'))
      img.src = backgroundImageUrl
    })
  }

  const backgroundImage = await loadBackgroundImage()

  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.src = URL.createObjectURL(file)
    video.muted = false

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      reject(new Error('Could not get canvas context'))
      return
    }
    const canvasStream = canvas.captureStream()

    let mediaRecorder: MediaRecorder
    const chunks: BlobPart[] = []

    video.onloadedmetadata = async () => {
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      const audioContext = new AudioContext()
      const sourceNode = audioContext.createMediaElementSource(video)
      const destination = audioContext.createMediaStreamDestination()
      sourceNode.connect(destination)

      const audioTracks = destination.stream.getAudioTracks()
      const videoTracks = canvasStream.getVideoTracks()
      const combinedStream = new MediaStream([...videoTracks, ...audioTracks])

      mediaRecorder = new MediaRecorder(combinedStream)
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data)
        }
      }

      mediaRecorder.onstop = () => {
        const finalBlob = new Blob(chunks, {type: 'video/mp4'})
        const finalFile = new File([finalBlob], `background_replaced_${file.name}`, {
          type: 'video/mp4',
        })
        resolve(finalFile)
      }

      video.play()
      mediaRecorder.start()

      const renderFrame = async () => {
        const segmentation = await net.segmentPerson(video, {
          internalResolution: 'medium',
          segmentationThreshold: 0.7,
        })

        // Clear the canvas first
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        // Draw the background image, scaling it to cover the canvas
        const scale = Math.max(
          canvas.width / backgroundImage.width,
          canvas.height / backgroundImage.height
        )
        const x = (canvas.width - backgroundImage.width * scale) / 2
        const y = (canvas.height - backgroundImage.height * scale) / 2

        ctx.drawImage(
          backgroundImage,
          x,
          y,
          backgroundImage.width * scale,
          backgroundImage.height * scale
        )

        // Create a temporary canvas for the person
        const personCanvas = document.createElement('canvas')
        personCanvas.width = canvas.width
        personCanvas.height = canvas.height
        const personCtx = personCanvas.getContext('2d')!

        // Draw the video frame to the temporary canvas
        personCtx.drawImage(video, 0, 0)
        const frameData = personCtx.getImageData(0, 0, canvas.width, canvas.height)

        // Apply the segmentation mask
        for (let i = 0; i < segmentation.data.length; i++) {
          const pixelIndex = i * 4
          if (!segmentation.data[i]) {
            // If not part of the person
            frameData.data[pixelIndex + 3] = 0 // Set alpha to 0
          }
        }

        // Put the masked person back
        personCtx.putImageData(frameData, 0, 0)

        // Draw the person over the background
        ctx.drawImage(personCanvas, 0, 0)

        if (!video.ended) {
          requestAnimationFrame(renderFrame)
        } else {
          mediaRecorder.stop()
        }
      }

      renderFrame()
    }

    video.onerror = (error) => {
      reject(new Error('Error processing video: ' + error))
    }
  })
}
