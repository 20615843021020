import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import axios from 'axios'
import '@tensorflow/tfjs'
import '@tensorflow/tfjs-backend-webgl'

import Nouislider from 'nouislider-react'
import VideoRecorder from 'react-video-recorder'
import {uploadVideoOnFirebase} from '../core/_requests'
// import Slider from '@mui';
import {v4 as uuidv4} from 'uuid'
import MessageAlert from '../../global/MessageAlert'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {checkVideoAlreadySent} from '../../../oportunities/Components/opportunity-list/core/_requests'
import {getAuth} from '../../../auth'
import VideoAnimation from '../../global/VideoAnimation'
import {applyBlurToVideo, applyImageBackgroundToVideo} from './helper'

interface VideoRecorderProps {
  currentStep: string
  setCurrentStep: Dispatch<SetStateAction<string>>
  isRecording: boolean
  setIsRecording: Dispatch<SetStateAction<boolean>>
  isVideoLoaded: boolean
  setIsVideoLoaded: Dispatch<SetStateAction<boolean>>
  url: string
  setUrl: Dispatch<SetStateAction<string>>
  images: string[]
  duration: number | null
  setDuration: Dispatch<SetStateAction<number | null>>
  currentTime: number
  setCurrentTime: Dispatch<SetStateAction<number>>
  sliderValue: number[]
  setSliderValue: Dispatch<SetStateAction<number[]>>
  thumbnailValue: number[]
  setThumbnailValue: Dispatch<SetStateAction<number[]>>
  thumbnailType?: String
  taskQueue: string[]
  setTaskQueue: Dispatch<SetStateAction<string[]>>
  videoLoadQueue?: boolean[]
  setVideoLoadQueue?: Dispatch<SetStateAction<boolean[]>>
  subscriptionDetails?: any
  type?: String
  isOnFreePlan?: any
  isTrimming?: Boolean
  setUploadVideoDocId?: any
  isBgBlur?: boolean
  imagePath?: string
}

const VideoRecorderLocal = ({
  currentStep,
  setCurrentStep,
  isRecording,
  setIsRecording,
  isVideoLoaded,
  setIsVideoLoaded,
  url,
  setUrl,
  images,
  duration,
  setDuration,
  sliderValue,
  setSliderValue,
  thumbnailValue,
  setThumbnailValue,
  currentTime,
  setCurrentTime,
  thumbnailType,
  taskQueue,
  setTaskQueue,
  videoLoadQueue,
  setVideoLoadQueue,
  subscriptionDetails,
  type,
  isOnFreePlan,
  isTrimming,
  setUploadVideoDocId,
  isBgBlur,
  imagePath,
}: VideoRecorderProps) => {
  // const videoRef = useRef<HTMLVideoElement>(null)
  const videoRef = useRef<any>(null)
  /// <reference lib="dom" />
  let auth = getAuth()
  const [selectedFrame, setSelectedFrame] = useState<string>('')
  const mediaRecorderRef = useRef<any | null>(null)
  const playerRef = useRef<HTMLVideoElement | null>(null)
  const chunksRef = useRef<Blob[]>([])
  const [stream, setStream] = useState<MediaStream | null>(null)
  const [isPlay, setIsPlay] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth <= 600)
  const recorderRef = useRef<any>(null)

  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  //Alert Starts
  const [message, setMessage] = useState('')
  const [alertType, setAlertType] = useState<string>('success')
  const [alertModal, setAlertModal] = useState(false)
  const alertToggle = () => {
    setAlertModal(!alertModal)
  }
  // Alert Ends
  const isIOSDevice = () => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      return true
    } else {
      return false
    }
  }

  const videoConstraints = {
    facingMode: 'user',
  }

  useEffect(() => {
    function handleResize() {
      setIsMobileScreen(window.innerWidth <= 600)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  useEffect(() => {
    console.log('*** url updated ***')
    if (url !== '') {
      if (['viewTrimmedVideo', 'thumbnail'].includes(currentStep)) {
        setTaskQueue((prevState) => {
          let temp = JSON.parse(JSON.stringify(prevState))
          temp.push('loadingTrimmedVideo')
          return [...temp]
        })
        window.setTimeout(() => {
          setTaskQueue((prevState) => {
            let temp = JSON.parse(
              JSON.stringify(prevState.filter((task) => task !== 'loadingTrimmedVideo'))
            )
            return [...temp]
          })
        }, 1500)
      }
    }
  }, [url])

  const getEnd = () => {
    let dur = duration || 0
    return dur > 60 ? 60 : dur
  }
  let timer: any = null
  const startRecording = () => {
    setIsRecording(true)
    setCurrentStep('recording')

    if (stream) {
      // mediaRecorderRef.current = new MediaRecorder(stream)
      mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable)
      mediaRecorderRef?.current?.start()
    }
  }

  useEffect(() => {
    setSelectedFrame('')
    localStorage.removeItem('static_image')
  }, [thumbnailValue])

  console.log('ThumbnailValues', thumbnailValue)

  const get4Frames = () => {
    let publicId = localStorage.getItem('public_id')
    let [startOffset, endOffset] = thumbnailValue
    let duration = endOffset - startOffset
    let screenShotDiff = duration / 4

    let tempStartOffset = Number(startOffset)
    let screenshotsArray = []
    for (let i = 0; i < 4; i++) {
      let url = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINAR_ID}/video/upload/so_${tempStartOffset}/v1695106012/${publicId}.jpg`

      // let url = `http://res.cloudinary.com/${process.env.REACT_APP_CLOUDINAR_ID}/video/upload/a_hflip/so_${tempStartOffset}/v1695106012/${publicId}.jpg`
      tempStartOffset += screenShotDiff
      screenshotsArray.push(url)
    }
    return screenshotsArray
  }

  console.log('get4Frames', get4Frames())

  const stopRecording = () => {
    // if (timer != null) {
    //   clearTimeout(timer)
    // }
    setCurrentStep('viewVideo')
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      setIsRecording(false)
      mediaRecorderRef.current.stop()
    }
    window.setTimeout(async () => {
      const canvas = canvasRef.current
      const context = canvas?.getContext('2d')
      // const blob = new Blob(chunksRef.current, {type: 'video/ogg'})
      // let filename = uuidv4() + '.ogg'
      const blob = new Blob(chunksRef.current, {type: 'video/mp4'})
      let filename = uuidv4() + '.mp4'
      const file = new File([blob], filename)
      const formData = new FormData()
      formData.append('file', file)
      formData.append('upload_preset', 'upload_preset')

      setTaskQueue((prevState) => {
        let temp = JSON.parse(JSON.stringify(prevState))
        temp.push('uploadingVideo')
        return [...temp]
      })
      try {
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINAR_ID}/video/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        let {public_id, url, duration} = response.data
        url = url.replace('.mkv', '.mp4')

        let splittedUrl = url.split('/upload/')
        let tempUrl = `${splittedUrl?.[0]}/upload/a_hflip/${splittedUrl?.[1]}`

        setDuration(duration)
        localStorage.setItem('public_id', public_id)
        localStorage.setItem('url', tempUrl)

        setUrl(tempUrl)
        // localStorage.setItem('url', url)

        // setUrl(url)
      } catch (error) {
        console.error('Error uploading video:', error)
      } finally {
        console.log('inside finally')
        console.log('taskQueue:', taskQueue)
        window.setTimeout(() => {
          setTaskQueue((prevState) => {
            let temp = JSON.parse(
              JSON.stringify(prevState.filter((task) => task !== 'uploadingVideo'))
            )
            return [...temp]
          })
          chunksRef.current = []
        }, 500)
      }
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop()
        })
        setStream(null)
      }
      window.setTimeout(() => {
        setIsVideoLoaded(true)
      }, 500)
    }, 500)
  }

  const stopRecorder = async (file: any) => {
    setShowProgressBar(true)
    let newFile = file
    if (isBgBlur) {
      newFile = await applyBlurToVideo(file)
    } else if (imagePath) {
      newFile = await applyImageBackgroundToVideo(file, imagePath)
    }
    setCurrentStep('viewVideo')

    setTaskQueue((prevState) => {
      let temp = JSON.parse(JSON.stringify(prevState))
      temp.push('uploadingVideo')
      return [...temp]
    })
    window.setTimeout(async () => {
      try {
        uploadVideoOnFirebase(
          newFile,
          (res: any, docId: any) => {
            if (res?.result) {
              // setUploadVideoDocId(docId)
              localStorage.setItem('video_document_id', docId)

              const responseData = res.result
              console.log(responseData, 'responseDataresponseDataresponseData')
              let {public_id, secure_url, duration} = responseData
              console.log(public_id, secure_url, duration, 'public_id, secure_url, duration')
              secure_url = secure_url.replace('.mkv', '.mp4')

              let splittedUrl = secure_url.split('/upload/')
              let tempUrl = ''

              if (isIOSDevice()) {
                tempUrl = `${splittedUrl?.[0]}/upload/${splittedUrl?.[1]}`
              } else {
                tempUrl = `${splittedUrl?.[0]}/upload/a_hflip/${splittedUrl?.[1]}`
              }

              setDuration(duration)
              localStorage.setItem('public_id', public_id)
              localStorage.setItem('url', tempUrl)

              setUrl(tempUrl)
              window.setTimeout(() => {
                setTaskQueue((prevState) => {
                  let temp = JSON.parse(
                    JSON.stringify(prevState.filter((task) => task !== 'uploadingVideo'))
                  )
                  return [...temp]
                })

                setIsVideoLoaded(true)
              }, 500)
            }

            console.log(res, 'responsebyfirebase')
          },
          (err: any) => {
            setMessage('Unable to upload video')
            setAlertType('error')
            alertToggle()
            setCurrentStep('recordVideo')
            setTaskQueue((prevState) => {
              let temp = JSON.parse(
                JSON.stringify(prevState.filter((task) => task !== 'uploadingVideo'))
              )
              return [...temp]
            })
            setShowProgressBar(false)
            setUploadProgress(0)
            console.log(err)
          },
          (progress: number) => {
            let displayedProgress = Math.floor(progress)
            setUploadProgress(displayedProgress)
            console.log('upload progress', displayedProgress)
            if (displayedProgress == 100) {
              setTimeout(() => {
                console.log('Just ending..')
                setShowProgressBar(false)
                setUploadProgress(0)
              }, 200)
            }
          }
        )
      } catch (error) {
        console.error('Error uploading video:', error)
        // Handle error (e.g., show error message to user)
        setShowProgressBar(false)
      }
    }, 500)
  }

  const handleDataAvailable = (event: any) => {
    if (event.data && event.data.size > 0) {
      chunksRef.current.push(event.data)
    }
  }
  const handleSliderChange = (values: number[]) => {
    console.log('Values', values)
    let videoEle = playerRef.current
    console.log(videoEle)
    if (videoEle) {
      let [start, ...rest] = values
      videoEle.currentTime = start
    }
    if (currentStep === 'trimVideo') {
      setSliderValue(values)
    } else setThumbnailValue(values)
  }

  const handleMuteUnmute = () => {
    const video = playerRef.current
    if (video)
      if (video?.muted) {
        video.muted = false
        setIsMuted(false)
      } else {
        video.muted = true
        setIsMuted(true)
      }
  }
  const handleVideoTimeUpdate = () => {
    const video = playerRef.current
    const seekBar = document.getElementById('seek-bar') as HTMLInputElement
    if (video && seekBar) {
      setCurrentTime(video.currentTime)
      const currentTime = (video.currentTime / (duration || 1)) * 100
      seekBar.value = String(currentTime)
      if (seekBar.value == '100') {
        setIsRecording(false)
        setIsPlay(false)
      }
    }
  }
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0]
    const maxSize: number = 3 * 1024 * 1024 //  3MB

    console.log('Selected file...', selectedFile)

    if (selectedFile) {
      // Check if the selected file is a video (you can customize this check)
      if (selectedFile.type.includes('video/')) {
        if (selectedFile.size <= maxSize) {
          setSelectedFile(selectedFile)
          // setCurrentStep('viewVideo')

          if (auth?.uid) {
            checkVideoAlreadySent(
              {
                sendTo: auth?.uid,
                videoName: selectedFile.name,
              },
              () => {
                localStorage.setItem('videoName', selectedFile.name)
                stopRecorder(selectedFile)
              },
              () => {
                setMessage(
                  `To ensure that all video messages are unique, you can't upload the same video twice. Sorry!`
                )
                setAlertType('warning')

                alertToggle()
                setSelectedFile(null)
              }
            )
          } else {
            stopRecorder(selectedFile)
          }
        } else {
          // alert('Video should be max 1MB')
          setMessage('Video should be max 3MB')
          setAlertType('warning')

          alertToggle()
          setSelectedFile(null)
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
        }
      } else {
        setMessage('Please select a video file.')
        setAlertType('warning')

        alertToggle()
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
      }
    } else {
      setSelectedFile(null)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && isRecording) {
        stopRecording()
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [isRecording])
  const getURL = () => {
    if (isOnFreePlan()) {
      if (url) {
        let newWaterMarkURL = url.split('/upload/')
        let newUrl = ''
        if (isIOSDevice()) {
          newUrl =
            newWaterMarkURL?.[0] +
            '/upload/' +
            `/l_${process.env.REACT_APP_WATERMARK_MOBILE}/fl_layer_apply/l_${process.env.REACT_APP_WATERMARK_MOBILE}/fl_layer_apply,y_35,x_30/l_${process.env.REACT_APP_WATERMARK_MOBILE}/fl_layer_apply,y_35,x_285/l_${process.env.REACT_APP_WATERMARK_MOBILE}/fl_layer_apply,y_530,x_30/l_${process.env.REACT_APP_WATERMARK_MOBILE}/fl_layer_apply,y_530,x_285/` +
            newWaterMarkURL?.[1]
        } else {
          if (isMobile) {
            newUrl =
              newWaterMarkURL?.[0] +
              '/upload/' +
              `/l_${process.env.REACT_APP_WATERMARK_REFLECTED_MOBILE}/fl_layer_apply/l_${process.env.REACT_APP_WATERMARK_REFLECTED_MOBILE}/fl_layer_apply,y_35,x_30/l_${process.env.REACT_APP_WATERMARK_REFLECTED_MOBILE}/fl_layer_apply,y_35,x_285/l_${process.env.REACT_APP_WATERMARK_REFLECTED_MOBILE}/fl_layer_apply,y_530,x_30/l_${process.env.REACT_APP_WATERMARK_REFLECTED_MOBILE}/fl_layer_apply,y_530,x_285/` +
              newWaterMarkURL?.[1]
          } else {
            newUrl =
              newWaterMarkURL?.[0] +
              '/upload/' +
              `l_${process.env.REACT_APP_WATERMARK_REFLECTED}/fl_layer_apply/l_${process.env.REACT_APP_WATERMARK_REFLECTED}/fl_layer_apply,y_35,x_30/l_${process.env.REACT_APP_WATERMARK_REFLECTED}/fl_layer_apply,y_35,x_350/l_${process.env.REACT_APP_WATERMARK_REFLECTED}/fl_layer_apply,y_350,x_30/l_${process.env.REACT_APP_WATERMARK_REFLECTED}/fl_layer_apply,y_350,x_350/` +
              newWaterMarkURL?.[1]
          }
        }

        return newUrl
      } else {
        return ''
      }
    } else {
      return url
    }
  }

  const [isRenderingTrimVideo, setIsRenderingTrimVideo] = useState(false)

  useEffect(() => {
    if (currentStep == 'trimVideo' || currentStep == 'thumbnail') {
      setIsRenderingTrimVideo(true)
      setTimeout(() => {
        setIsRenderingTrimVideo(false)
      }, 1000)
    }
  }, [currentStep])

  useEffect(() => {
    if (isTrimming && isTrimming == true) {
      playerRef.current?.pause()
      setIsPlay(false)
    }
  }, [isTrimming])

  useEffect(() => {
    const updatePredicate = () => {
      setIsMobile(window.innerWidth < 576)
    }

    updatePredicate() // Initial check

    window.addEventListener('resize', updatePredicate)

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updatePredicate)
    }
  }, [])

  const handleTogglePlay = () => {
    if (isPlay) {
      playerRef.current?.pause()
      setIsPlay(false)
    } else if (currentStep == 'trimVideo' && sliderValue.length === 2 && playerRef.current) {
      // Check if sliderValue has start and end times and playerRef is not null
      playerRef.current.currentTime = sliderValue[0]
      setIsPlay(true)
      playerRef.current.play()
      const endTime = sliderValue[1] // Cast or type sliderValue as string[]

      playerRef.current.addEventListener('timeupdate', function listener() {
        if (!playerRef.current) return
        console.log(
          'Run listerner of trim',
          playerRef.current?.currentTime >= endTime,
          playerRef.current?.currentTime,
          endTime
        )
        if (playerRef.current?.currentTime >= endTime) {
          console.log('inside run..')
          playerRef.current.currentTime = sliderValue[0]
          playerRef.current?.pause()
          setIsPlay(false)
        }
      })
    } else if (currentStep == 'thumbnail' && thumbnailValue.length === 2 && playerRef.current) {
      // Check if sliderValue has start and end times and playerRef is not null
      playerRef.current.currentTime = thumbnailValue[0]
      setIsPlay(true)
      playerRef.current.play()
      const endTime = thumbnailValue[1] // Cast or type thumbnailValue as string[]

      playerRef.current.addEventListener('timeupdate', function listener() {
        if (!playerRef.current) return

        if (playerRef.current?.currentTime >= endTime) {
          playerRef.current.currentTime = thumbnailValue[0]
          playerRef.current?.pause()
          setIsPlay(false)
        }
      })
    } else {
      playerRef.current?.play()
      setIsPlay(true)
    }
  }

  return (
    <>
      {(showProgressBar || taskQueue.includes('uploadingVideo')) && (
        <div className='d-flex upload-video-text'>
          {showProgressBar ? (
            <span>Uploading is in progress {uploadProgress}%</span>
          ) : (
            <span>Optimizing video is in progress...</span>
          )}
        </div>
      )}
      {/* {JSON.stringify(taskQueue)} */}
      {((isTrimming && isTrimming == true) || isRenderingTrimVideo) && <VideoAnimation />}

      <canvas ref={canvasRef} style={{display: 'none'}} />
      {currentStep === 'preview' ? (
        <>
          <div className=''>
            <label>Video:</label>
            <video controls={true} className='w-100 video-container mt-1'>
              <source src={localStorage.getItem('trimmedUrlWithWatermark') || ''}></source>
            </video>
          </div>
          <div className='mt-4'>
            {thumbnailType && thumbnailType == 'staticImage' ? (
              <div>
                <label>Static Thumbnail:</label>
                <img className='w-100' src={localStorage.getItem('static_image') || ''} />
              </div>
            ) : (
              <>
                <label>Animated Thumbnail:</label>
                <video controls={true} className='w-100 video-container mt-1'>
                  <source src={localStorage.getItem('animatedThumbnailUrl') || ''}></source>
                </video>
              </>
            )}
          </div>
        </>
      ) : taskQueue.length !== 0 || showProgressBar ? (
        <div className='position-relative rounded overflow-hidden'>
          <div className='video-container bg-black w-100 d-flex align-items-center justify-content-center'>
            <VideoAnimation />
          </div>
        </div>
      ) : !url ? (
        <>
          <input
            type='file'
            accept='video/*'
            onChange={handleFileChange}
            hidden
            ref={fileInputRef} // Assign the ref to the file input
          />
          {isIOSDevice() ? (
            <label
              onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.click()
                }
              }}
              className={`video-upload-section btn btn-outline btn-outline-dashed btn-outline-default d-flex align-items-center justify-content-center py-5`}
              htmlFor='kt_create_account_form_account_type_personal'
            >
              <span className='d-block fw-semibold text-center'>
                {/* <i className='fa fa-camera fa-10x text-dark'></i> */}
                <img src={toAbsoluteUrl('/media/icons/upload_video.svg')} alt='img' />

                <span className='text-dark  d-block   text-nowrap mt-3'>Upload Video</span>
              </span>
            </label>
          ) : (
            <div className='position-relative rounded overflow-hidden video-container'>
              <VideoRecorder
                ref={recorderRef}
                onRecordingComplete={(videoBlob: any) => {
                  let filename = uuidv4() + '.mp4'

                  let tempFile: any = new File([videoBlob], filename, {type: 'video/mp4'})
                  stopRecorder(tempFile)
                }}
                onError={(e: any) => {
                  console.log('Error in recorder', e)
                }}
                isFlipped={true}
                isOnInitially={true}
                countdownTime={3000}
                // timeLimit={type && type == 'send' ? 30000 : 60000}
                timeLimit={60000}
              />
            </div>
          )}
        </>
      ) : isVideoLoaded ? (
        <div className='position-relative rounded overflow-hidden'>
          {currentStep === 'viewVideo' || currentStep === 'viewTrimmedVideo' ? (
            <div onClick={handleMuteUnmute} className='mute-btn text-white'>
              <i className={`fas fa-volume-${isMuted ? 'mute' : 'up'} text-white`}></i>
            </div>
          ) : (
            ''
          )}
          {currentStep === 'viewVideo' ? (
            <>
              <video
                preload='metadata'
                onLoadStart={() => {
                  setVideoLoadQueue?.((prevState) => {
                    return [...prevState, true]
                  })
                }}
                onLoadedData={() => {
                  setVideoLoadQueue?.((prevState) => {
                    prevState.pop()
                    return [...prevState]
                  })
                }}
                onTimeUpdate={handleVideoTimeUpdate}
                ref={playerRef}
                controls={
                  (currentStep as any) === 'trimVideo' || (currentStep as any) === 'thumbnail'
                    ? false
                    : true
                }
                // autoPlay
                className='video-container bg-black w-100'
              >
                <source
                  src={currentStep == 'viewVideo' ? `${getURL()}#t=0.001` : `${url}#t=0.001`}
                  id='video-source'
                ></source>
              </video>
            </>
          ) : taskQueue.length === 0 ? (
            <>
              <video
                onTimeUpdate={handleVideoTimeUpdate}
                preload='metadata'
                ref={playerRef}
                controls={
                  (currentStep as any) === 'trimVideo' || (currentStep as any) === 'thumbnail'
                    ? false
                    : true
                }
                className='video-container bg-black w-100'
              >
                <source src={`${url}#t=0.001`} id='video-source'></source>
              </video>
            </>
          ) : (
            ''
          )}
          {videoLoadQueue &&
          videoLoadQueue.length == 0 &&
          (currentStep === 'viewVideo' || currentStep === 'viewTrimmedVideo') ? (
            <></>
          ) : (currentStep === 'trimVideo' || currentStep === 'thumbnail') &&
            taskQueue.length == 0 ? (
            <>
              <div className='video-slider video-slider--lg px-4 d-flex align-items-center'>
                <i
                  onClick={handleTogglePlay}
                  style={{fontSize: '18px'}}
                  className={`me-3 fa fa-${!isPlay ? 'play' : 'pause'} text-white`}
                ></i>
                <div className='d-flex w-100'>
                  <Nouislider
                    onUpdate={handleSliderChange as () => void}
                    style={{width: '100%'}}
                    behaviour='drag'
                    // limit={currentStep === 'trimVideo' ? (type && type == 'send' ? 30 : 60) : 3}
                    limit={currentStep === 'trimVideo' ? 60 : 3}
                    range={{min: 0, max: duration || 0}}
                    start={[0, getEnd()]}
                    connect
                  />
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div className='position-relative rounded overflow-hidden'>
          <div className='video-container bg-black w-100'></div>
        </div>
      )}

      {currentStep == 'thumbnail' && (
        <h4 className='mt-4'>
          Please use the slider above to select a section of your video which will be embed into
          your email.
        </h4>
      )}

      {currentStep === 'thumbnail' && thumbnailType === 'staticImage' ? (
        <>
          <h4 className='mt-4'>Select static image:</h4>
          <div className='row row-cols-4 mt-2'>
            {get4Frames().map((frame, index) => (
              <div key={index}>
                <div
                  onClick={() => {
                    setSelectedFrame(frame)
                    localStorage.setItem('static_image', frame)
                  }}
                  className={`cursor-pointer rounded-sm ${
                    selectedFrame == frame ? 'border border-2 border-white' : ''
                  }`}
                >
                  <img className='w-100' src={frame} />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        ''
      )}

      {currentStep === 'trimVideo' && taskQueue.length == 0 && (
        <>
          <h3 className='mt-3'>
            {' '}
            Duration: {duration && (Math.floor(duration * 1000) / 1000).toFixed(2)}s
          </h3>
          {sliderValue.length > 0 && (
            <div className='d-flex justify-content-between'>
              <span>Start: {sliderValue[0]}s</span> <span>End: {sliderValue[1]}s</span>
            </div>
          )}
        </>
      )}

      <MessageAlert type={alertType} message={message} isOpen={alertModal} toggle={alertToggle} />
    </>
  )
}

export default VideoRecorderLocal
