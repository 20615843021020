import axios from 'axios'
import firebase from 'firebase/app'
import {ID} from '../../../../../../_metronic/helpers'
import {formatedDate} from '../../../../../../_metronic/helpers/FormatDate'
import {RepositoryFactory} from '../../../../../repository/RepositoryFactory'
import {getUserIdToUpdateStats} from '../../../../../utils/getUserIdToUpdateStats'
import {User} from './_models'
import {getPricingQuota} from '../../../../../utils/getPricingQuota'
import {getAuth} from '../../../../auth'
import {getUserById} from '../../../../apps/user-management/users-list/core/_requests'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`
let UserApi = RepositoryFactory.get('user')

// =========OLD FUNCTION===========
// const getOpportunities = (query: string): Promise<UsersQueryResponse> => {
//   return axios
//     .get(`${GET_USERS_URL}?${query}`)
//     .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
// }

// =========NEW FUNCTION===========
const getOpportunities: any = async (query: string, type: string = '') => {
  try {
    const currentUser = firebase.auth().currentUser
    let opportunitySnapshot: any = []

    if (query.includes('&userId=')) {
      const id = query.split('&userId=')[1]
      if (id.includes(',')) {
        const opportunitySnapshots = []
        const idArray: any = id.split(',')
        idArray.push(currentUser?.uid)
        for (const singleId of idArray) {
          opportunitySnapshot = await firebase
            .firestore()
            .collection('opportunity')
            .where('createdById', '==', singleId)
            .get()
          opportunitySnapshots.push(opportunitySnapshot)
        }
        opportunitySnapshot = opportunitySnapshots.reduce((acc, cur) => {
          return [...acc, cur]
        }, [])
        console.log('opportunitySnapshot', opportunitySnapshot)
      } else {
        console.log('first')
        opportunitySnapshot = await firebase
          .firestore()
          .collection('opportunity')
          .where('createdById', '==', id)
          .get()
        console.log('opportunitySnapshot', opportunitySnapshot)
      }
    } else if (type !== '') {
      opportunitySnapshot = await firebase
        .firestore()
        .collection('opportunity')
        .where('opportunityType', '==', type)
        .where('createdById', '==', currentUser?.uid)
        .get()
    } else {
      opportunitySnapshot = await firebase
        .firestore()
        .collection('opportunity')
        .where('createdById', '==', currentUser?.uid)
        .get()
    }
    let opportunityData: any
    if (Array.isArray(opportunitySnapshot)) {
      // Merge and flatten the array of query snapshots
      opportunityData = opportunitySnapshot
        .reduce((acc, cur) => {
          return [...acc, ...cur.docs]
        }, [])
        .map((doc: any) => ({
          id: doc.id,
          ...doc.data(),
        }))
    } else {
      opportunityData = opportunitySnapshot?.docs?.map((doc: any) => ({
        id: doc.id,
        ...doc.data(),
      }))
    }

    let filteredData = opportunityData
    filteredData.sort((a: any, b: any) => b.createdAt - a.createdAt)

    if (query.includes('&startdate=') && query.includes('enddate') && !query.includes('&userId')) {
      let date = query.split('&startdate=')[1]
      let start = date.split('&enddate=')[0]
      let end = date.split('&enddate=')[1]
      const startDate = new Date(start)
      const endDate = new Date(end)

      filteredData = filteredData.filter((item: any) => {
        const createdAtDate = new Date(formatedDate(item.createdAt))
        return createdAtDate >= startDate && createdAtDate <= endDate
      })
    }
    if (query.includes('&startdate=') && query.includes('enddate') && query.includes('&userId')) {
      let date = query.split('&startdate=')[1]
      let start = date.split('&enddate=')[0]
      let end2 = date.split('&enddate=')[1]
      let end = end2.split('&userId=')[0]
      const startDate = new Date(start)
      const endDate = new Date(end)

      filteredData = filteredData.filter((item: any) => {
        const createdAtDate = new Date(formatedDate(item.createdAt))
        return createdAtDate >= startDate && createdAtDate <= endDate
      })
    }
    console.log('query==>', query)
    if (
      query.includes('&search=') &&
      !query.includes('&filter_role=') &&
      !query.includes('&startdate=') &&
      !query.includes('&userId=')
    ) {
      const searchTerm = query.split('&search=')[1].toLowerCase()
      filteredData = filteredData.filter((data: any) =>
        data.opportunityName.toLowerCase().includes(searchTerm)
      )
    }
    if (query.includes('&search=') && query.includes('&startdate=')) {
      const searchTerm = query.split('&search=')[1].toLowerCase()
      const searchTerm2 = searchTerm.split('&startdate')[0].toLowerCase()
      filteredData = filteredData.filter((data: any) =>
        data.opportunityName.toLowerCase().includes(searchTerm2)
      )
    }
    if (
      query.includes('&search=') &&
      !query.includes('&startdate=') &&
      query.includes('&userId=')
    ) {
      const search = query.split('&search=')[1].toLowerCase()
      const search2 = search.split('&userid')[0].toLowerCase()
      filteredData = filteredData.filter((data: any) =>
        data.opportunityName.toLowerCase().includes(search2)
      )
    }

    if (
      query.includes('&filter_role=') &&
      !query.includes('&search=') &&
      !query.includes('&startdate=')
    ) {
      const filterTerm = query.split('&filter_role=')[1].toLowerCase()
      filteredData = filteredData.filter((data: any) =>
        data.opportunityName.toLowerCase().includes(filterTerm)
      )
    }

    if (query.includes('&search=') && query.includes('&filter_role=')) {
      if (query.split('&filter_role=')[1].length > 0) {
        const filterTerm = query.split('&filter_role=')[1].toLowerCase()
        let newFilteredData = filteredData.filter((data: any) =>
          data.opportunityName.toLowerCase().includes(filterTerm)
        )
        // const searchTerm = query.split('&search=')[1].toLowerCase()
        const dynamicValueString = query.split('&search=')[1]
        const dynamicValue = dynamicValueString.split('&filter_role=')[0].toLowerCase()

        filteredData = newFilteredData.filter((data: any) =>
          data.name.toLowerCase().includes(dynamicValue)
        )
      }
    }

    // HANDLE PAGINATION
    const page = parseInt(query.split('page=')[1].split('&')[0])
    const itemsPerPage = parseInt(query.split('items_per_page=')[1])
    const totalPages = Math.ceil(filteredData.length / itemsPerPage)

    const startIndex = (page - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    // GENERATING PAGINATION LINKS ARRAY DYNAMICALLY
    const paginatedData = filteredData.slice(startIndex, endIndex)
    const links = []
    if (totalPages > 0) {
      // PREVIOUS BUTTON
      links.push({
        url: page > 1 ? `/?page=${page - 1}` : null,
        label: '&laquo; Previous',
        active: false,
        page: page > 1 ? page - 1 : null,
      })

      // PAGE NUMBERS
      for (let i = 1; i <= totalPages; i++) {
        const url = `/?page=${i}`
        const label = i.toString()
        const active = i === page
        links.push({url, label, active, page: i})
      }

      // NEXT BUTTON
      links.push({
        url: page < totalPages ? `/?page=${page + 1}` : null,
        label: 'Next &raquo;',
        active: false,
        page: page < totalPages ? page + 1 : null,
      })
    }

    return {
      data: paginatedData,
      payload: {
        pagination: {
          first_page_url: `/?page=1`,
          from: startIndex + 1,
          items_per_page: itemsPerPage,
          last_page: totalPages,
          links,
          next_page_url: page < totalPages ? `/?page=${page + 1}` : null,
          page,
          prev_page_url: page > 1 ? `/?page=${page - 1}` : null,
          to: endIndex,
          total: filteredData.length,
        },
      },
    }
  } catch (error) {
    console.error('Error getting Opportunities: ', error)
  }
}

const getOpportunityById: any = async (id: ID) => {
  try {
    const userSnapshot = await firebase.firestore().collection('opportunity').doc(id).get()
    const requestVideoCollection = await firebase
      .firestore()
      .collection('requestVideo')
      .where('opportunity', '==', id)
      .get()

    if (!userSnapshot.exists) {
      throw new Error('Opportunity not found')
    }

    const userData = {
      id: userSnapshot.id,
      ...userSnapshot.data(),
      numberOfRequests: requestVideoCollection.size,
    }
    return userData
  } catch (error) {
    console.error('Error getting opportunity: ', error)
  }
}
const getRecordedVideos = async (id: any, type: string) => {
  try {
    const opportunitiesSnapshot = await firebase
      .firestore()
      .collection('opportunity')
      .where('createdById', '==', id)
      .where('opportunityType', '==', type)
      .get()

    const opportunitiesData: any = []

    opportunitiesSnapshot.forEach((opportunityDoc) => {
      opportunitiesData.push({id: opportunityDoc.id})
    })

    const recordedVideosPromises = opportunitiesData.map(async (element: any) => {
      const res = await firebase
        .firestore()
        .collection('recordedVideos')
        .where('opportunity', '==', element.id)
        .orderBy('createdAt', 'desc')
        .get()

      const videosData = res.docs.map(async (doc) => {
        const videoData: any = {id: doc.id, ...doc.data()}

        // Fetch contact details based on the sendTo field
        const sendTo = videoData.sendTo
        if (sendTo) {
          const contactDoc = await firebase.firestore().collection('contacts').doc(sendTo).get()
          if (contactDoc.exists) {
            videoData.contactDetails = contactDoc.data()
          }
        }

        return videoData
      })

      return Promise.all(videosData)
    })

    const recordedVideos = await Promise.all(recordedVideosPromises)

    // Flatten the array of arrays into a single array
    // const data = [].concat(...recordedVideos)
    const data = ([] as any[]).concat(...recordedVideos)

    console.log(data)
    return data
  } catch (error) {
    console.error('Error getting Videos: ', error)
    throw error // Re-throw the error to handle it at a higher level
  }
}

// const getRecordedVideosOnOneOpportunity: any = async (id: ID) => {
//   try {
//     const videosSnapshot = await firebase
//       .firestore()
//       .collection('recordedVideos')
//       .where('opportunity', '==', id)
//       .orderBy('createdAt', 'desc')
//       .get()
//     const data: any = []
//     videosSnapshot.forEach((doc) => {
//       data.push({id: doc.id, ...doc.data()})
//     })
//     return data
//   } catch (error) {
//     console.error('Error getting Videos: ', error)
//   }
// }
const getRecordedVideosOnOneOpportunity = async (id: any) => {
  try {
    const videosSnapshot = await firebase
      .firestore()
      .collection('recordedVideos')
      .where('opportunity', '==', id)
      .orderBy('createdAt', 'desc')
      .get()

    const data: any = []
    const contactDetailsPromises: any = []

    videosSnapshot.forEach((doc) => {
      const videoData: any = {id: doc.id, ...doc.data()}
      data.push(videoData)

      // Fetch contact details based on the sendTo field
      const sendTo = videoData.sendTo
      if (sendTo) {
        const contactPromise = firebase
          .firestore()
          .collection('contacts')
          .doc(sendTo)
          .get()
          .then((contactDoc) => {
            if (contactDoc.exists) {
              videoData.contactDetails = contactDoc.data()
            }
          })
          .catch((error) => {
            console.error('Error getting Contact Details: ', error)
          })

        contactDetailsPromises.push(contactPromise)
      }
    })

    // Wait for all contact details promises to resolve
    await Promise.all(contactDetailsPromises)
    console.log(data)
    return data
  } catch (error) {
    console.error('Error getting Videos: ', error)
    throw error // Propagate the error so that the caller can handle it
  }
}

// =========NEW FUNCTION===========
const createOpportunity = async (
  userInfo: User,
  onSuccess = (id: string) => {},
  onError = () => {}
): Promise<string | void> => {
  console.log(userInfo)
  try {
    const currentUser = firebase.auth().currentUser
    if (!currentUser) {
      throw new Error('No authenticated user found')
    }
    if (userInfo.opportunityType === 'requestVideo') {
      const opportunityRef = await firebase
        .firestore()
        .collection('opportunity')
        .add({
          opportunityName: userInfo.opportunityName,
          instructions: userInfo.instructions,
          opportunityType: userInfo.opportunityType,
          opportunityStats: {
            videosRequested: 0,
            videosReceived: 0,
            videosViewed: 0,
            videosNotOpened: 0,
          },
          createdAt: firebase.firestore.Timestamp.now(),
          createdById: currentUser.uid,
        })
      onSuccess(opportunityRef.id)
      return opportunityRef.id
    } else {
      const opportunityRef = await firebase
        .firestore()
        .collection('opportunity')
        .add({
          opportunityName: userInfo.opportunityName,
          instructions: userInfo.instructions,
          opportunityType: userInfo.opportunityType,
          opportunityStats: {
            videosSent: 0,
            videosOpened: 0,
            videosNotOpened: 0,
            videosViewed: 0,
            interested: 0,
            notInterested: 0,
          },
          createdAt: firebase.firestore.Timestamp.now(),
          createdById: currentUser.uid,
        })
      onSuccess(opportunityRef.id)
      return opportunityRef.id
    }
  } catch (error) {
    console.error('Error adding user: ', error)
    onError()
  }
}

const updateOpportunity = async (user: User): Promise<string | void> => {
  try {
    const userRef = firebase.firestore().collection('opportunity').doc(user.id)

    await userRef.update({
      ...user,
      updatedAt: firebase.firestore.Timestamp.now(),
    })
  } catch (error) {
    console.error('Error In updating opportunity: ', error)
  }
}

const deleteOpportunity = async (documentId: ID, onSuccess = () => {}, onError = () => {}) => {
  try {
    const userId = getUserIdToUpdateStats()

    const videoRequests = await firebase
      .firestore()
      .collection('requestVideo')
      .where('opportunity', '==', documentId)
      .get()
    const videoRecords = await firebase
      .firestore()
      .collection('recordedVideos')
      .where('opportunity', '==', documentId)
      .get()

    await firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .update({
        'usage.videoMessages': firebase.firestore.FieldValue.increment(-videoRecords.size),
        'usage.storage': firebase.firestore.FieldValue.increment(-videoRecords.size * 0.1),
      })

    const notesDeletePromises: any = videoRecords.docs.map(async (doc) => {
      const notesQuerySnapshot: any = await firebase
        .firestore()
        .collection('recordedVideos')
        .doc(doc.id)
        .collection('notes')
        .get()
      const deleteNotePromises: any = notesQuerySnapshot.docs.map((noteDoc: any) =>
        noteDoc.ref.delete()
      )
      return Promise.all(deleteNotePromises)
    })

    await Promise.all([
      firebase.firestore().collection('opportunity').doc(documentId).delete(),
      ...videoRequests.docs.map((doc) => doc.ref.delete()),
      ...videoRecords.docs.map((doc) => doc.ref.delete()),
      ...notesDeletePromises,
    ])

    console.log('Document deleted successfully!')
    onSuccess()
  } catch (error) {
    console.error('Error deleting document: ', error)
    onError()
  }
}

const deleteSelectedOpportunities = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const requestVideo = async (data: any, onSuccess = (id: any) => {}, onError = () => {}) => {
  try {
    const response = await firebase
      .firestore()
      .collection('requestVideo')
      .add({
        ...data,
        status: 'pending',
        createdAt: firebase.firestore.Timestamp.now(),
      })

    handleOpportunityStats(data.opportunity, 'videosRequested')
    console.log('request video success', response)
    onSuccess(response?.id)
    return response
  } catch (error) {
    onError()
    console.log('request video error', error)
    return error
  }
}

const updateRequestVideo = async (user: any): Promise<string | void> => {
  try {
    const userRef = firebase.firestore().collection('requestVideo').doc(user.id)

    await userRef.update({
      ...user,
      updatedAt: firebase.firestore.Timestamp.now(),
    })
  } catch (error) {
    console.error('Error In updating opportunity: ', error)
  }
}

const sendEmail = async (data: any, onSuccess = () => {}, onError = (err: any) => {}) => {
  try {
    // let res

    // if (data.unixTime === 0) {
    //   res = await UserApi.sendEmail(data)
    // } else {
    //   res = await UserApi.sendEmailSchedule(data)
    // }

    let res = await UserApi.sendEmail(data)

    console.log(res)
    onSuccess()
  } catch (error: any) {
    console.log(error)
    onError(error?.response?.data?.error)
  }
}

const isIOSDevice = () => {
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

// const sendVideo = async (
//   payload: any,
//   onSuccess = (id: string) => {},
//   onError = (err: any) => {}
// ): Promise<string | void> => {
//   console.log('sendVidepayload', payload)
//   try {
//     const currentUser = firebase.auth().currentUser
//     const userId = getUserIdToUpdateStats()
//     console.log('userId:', userId)
//     if (!currentUser) {
//       throw new Error('No authenticated user found')
//     }
//     let auth: any = getAuth()
//     let user: any = null
//     let priceId = null
//     if (auth.role !== 'super-admin') {
//       let userData = await getUserById(auth.createdById)
//       user = userData
//       priceId = userData.priceId
//     } else {
//       user = auth
//       priceId = auth.priceId
//     }
//     let features: any = await getPricingQuota(priceId || '')
//     console.log('Features...', features, user)
//     if (!user?.allowFreeAccess && !features) {
//       // alert('You have used your available quota. Upgrade you plan to send more videos.')
//       onError('You have used your available quota. Upgrade you plan to send more videos.')
//       return
//     }
//     if (!auth) {
//       // alert('You are not logged in')
//       onError('You are not logged in')

//       return
//     }

//     console.log(
//       'Checking the data',
//       !user?.allowFreeAccess,
//       typeof features.storage != 'string',
//       user.usage.storage >= features.storage
//     )

//     if (
//       !user?.isAllowFreeAccess &&
//       typeof features.storage == 'string' &&
//       !features.storage.toLowerCase().includes('unlimited')
//     ) {
//       console.log('First run..')

//       onError('You have used your available quota. Upgrade you plan to send more videos.')
//       return
//     } else if (
//       !user?.allowFreeAccess &&
//       typeof features.storage != 'string' &&
//       user.usage.storage >= features.storage
//     ) {
//       console.log('Storage full')
//       onError('You have used your available quota. Upgrade you plan to send more videos.')
//       return
//     } else {
//       console.log('Else run...of send video')
//     }

//     if (
//       !user?.isAllowFreeAccess &&
//       typeof features.videoMessages == 'string' &&
//       !features.videoMessages.toLowerCase().includes('unlimited')
//     ) {
//       onError('You have used your available quota. Upgrade you plan to send more videos.')
//       return
//     } else if (
//       !user?.allowFreeAccess &&
//       typeof features.videoMessages != 'string' &&
//       user.usage.videoMessages >= Number(features.videoMessages)
//     ) {
//       console.log('Running of video message check...', user, features)
//       onError('You have used your available quota. Upgrade you plan to send more videos.')
//       return
//     } else {
//       console.log('Else run.. video messages')
//     }

//     // if (
//     //   !user?.allowFreeAccess &&
//     //   (user.usage.storage >= features.storage || user.usage.videoMessages >= features.videoMessages)
//     // ) {
//     //   onError('You have used your available quota. Upgrade you plan to send more videos.')
//     //   return
//     // }

//     let sendTo = ''
//     console.log('userId:', userId)
//     let {notes, contactDetails, opportunityDetails, videoDetails, subject,captions} = payload
//     let opportunityId = ''

//     console.log('In Action captions',captions);

//     if (contactDetails?.contactType != 'existing') {
//       let newContactDetails = await firebase
//         .firestore()
//         .collection('contacts')
//         .add({
//           name: contactDetails?.firstName + ' ' + contactDetails?.lastName,
//           firstName: contactDetails?.firstName,
//           lastName: contactDetails?.lastName,
//           companyName: contactDetails?.companyName,
//           email: contactDetails?.email,
//           telephone: contactDetails?.telephone,
//           createdAt: firebase.firestore.Timestamp.now(),
//           createdById: currentUser.uid,
//         })

//       sendTo = newContactDetails?.id
//     } else {
//       sendTo = contactDetails?.sendTo
//     }

//     if (opportunityDetails.opportunityType == 2) {
//       let details = await firebase
//         .firestore()
//         .collection('opportunity')
//         .add({
//           opportunityName: opportunityDetails.opportunity,
//           instructions: opportunityDetails.instructions,
//           opportunityStats: {
//             videosSent: 0,
//             videosOpened: 0,
//             videosNotOpened: 0,
//             videosViewed: 0,
//             interested: 0,
//             notInterested: 0,
//           },
//           createdAt: firebase.firestore.Timestamp.now(),
//           createdById: currentUser.uid,
//           opportunityType: 'sendVideo',
//         })

//       console.log('Details', details)

//       opportunityId = details?.id
//     } else {
//       opportunityId = opportunityDetails?.opportunity
//     }

//     console.log('userId:', userId)
//     let obj: any = {
//       sendTo,
//       opportunity: opportunityId,
//       buttonText: opportunityDetails?.interestedText || '',
//       thumbnailType: videoDetails?.thumbnailType || '',
//       videoTitle: videoDetails?.videoTitle || '',
//       thumbnailUrl: videoDetails?.thumbnailUrl || '',
//       videoUrl: videoDetails?.url || '',
//       videoSize: videoDetails?.videoSize || 0,
//       // fileUrl: '',
//       callAction: opportunityDetails?.callAction,
//       subject,
//     }

//     let videoName: string = localStorage.getItem('videoName') || ''

//     if (isIOSDevice() && videoName && videoName != '') {
//       obj = {
//         ...obj,
//         videoName,
//       }
//     }

//     if (obj.thumbnailType != 'staticImage') {
//       let newUrl = videoDetails?.thumbnailUrl.replace('.mp4', '.gif')
//       obj.thumbnailUrl = newUrl
//     }

//     console.log(opportunityDetails.callActionFile)
//     if (opportunityDetails?.callAction == 'fileDownload') {
//       if (typeof opportunityDetails?.callActionFile == 'string') {
//         obj.fileUrl = opportunityDetails.callActionFile
//         obj.fileName = opportunityDetails?.fileName || ''
//       } else {
//         const imageFile = opportunityDetails.callActionFile
//         const fileName = opportunityDetails.callActionFile.name
//         const fileExtension = fileName.slice(fileName.lastIndexOf('.'))
//         const fileNameWithExtension = currentUser.uid + fileExtension.toLowerCase()
//         const storageRef = firebase.storage().ref('action files/' + fileNameWithExtension)
//         const uploadTaskSnapshot = await storageRef.put(imageFile)
//         const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL()
//         obj.fileUrl = downloadURL
//         obj.fileName = fileName
//       }
//     }

//     const newVideoRef = await firebase
//       .firestore()
//       .collection('recordedVideos')
//       .add({
//         ...obj,
//         count: 0,
//         fullyWatched: false,
//         partiallyWatched: false,
//         isOpened: false,
//         recipientReaction: '',
//         openedDate: null,
//         createdAt: firebase.firestore.Timestamp.now(),
//         sendById: currentUser.uid,
//         fullyWatchedCount: 0,
//         partiallyWatchedCount: 0,
//         isOpenedCount: 0,
//         captions: captions.url !=undefined? null : captions,

//       })
//     let parentRef = firebase.firestore().collection('recordedVideos').doc(newVideoRef.id)
//     parentRef.collection('notes').add({note: notes, createdAt: firebase.firestore.Timestamp.now()})
//     await firebase
//       .firestore()
//       .collection('users')
//       .doc(userId)
//       .update({
//         'usage.videoMessages': firebase.firestore.FieldValue.increment(1),
//         'usage.storage': firebase.firestore.FieldValue.increment(videoDetails?.videoSize || 0),
//       })
//     await handleOpportunityStats(obj.opportunity, 'videosSent')
//     await handleOpportunityStats(obj.opportunity, 'videosNotOpened')
//     onSuccess(newVideoRef?.id)
//     return newVideoRef?.id
//   } catch (error: any) {
//     console.error('Error adding user: ', error)
//     onError(error?.message)
//   }
// }

const sendVideo = async (
  payload: any,
  onSuccess = (id: string) => {},
  onError = (err: any) => {}
): Promise<string | void> => {
  console.log('sendVidepayload', payload)
  try {
    const currentUser = firebase.auth().currentUser
    const userId = getUserIdToUpdateStats()
    console.log('userId:', userId)
    if (!currentUser) {
      throw new Error('No authenticated user found')
    }
    let auth: any = getAuth()
    let user: any = null
    let priceId = null
    if (auth.role !== 'super-admin') {
      let userData = await getUserById(auth.createdById)
      user = userData
      priceId = userData.priceId
    } else {
      user = auth
      priceId = auth.priceId
    }
    let features: any = await getPricingQuota(priceId || '')
    console.log('Features...', features, user)
    if (!user?.allowFreeAccess && !features) {
      // alert('You have used your available quota. Upgrade you plan to send more videos.')
      onError('You have used your available quota. Upgrade you plan to send more videos.')
      return
    }
    if (!auth) {
      // alert('You are not logged in')
      onError('You are not logged in')

      return
    }

    console.log(
      'Checking the data',
      !user?.allowFreeAccess,
      typeof features.storage != 'string',
      user.usage.storage >= features.storage
    )

    if (
      !user?.isAllowFreeAccess &&
      typeof features.storage == 'string' &&
      !features.storage.toLowerCase().includes('unlimited')
    ) {
      console.log('First run..')

      onError('You have used your available quota. Upgrade you plan to send more videos.')
      return
    } else if (
      !user?.allowFreeAccess &&
      typeof features.storage != 'string' &&
      user.usage.storage >= features.storage
    ) {
      console.log('Storage full')
      onError('You have used your available quota. Upgrade you plan to send more videos.')
      return
    } else {
      console.log('Else run...of send video')
    }

    // if (
    //   !user?.isAllowFreeAccess &&
    //   typeof features.videoMessages == 'string' &&
    //   !features.videoMessages.toLowerCase().includes('unlimited')
    // ) {
    //   onError('You have used your available quota. Upgrade you plan to send more videos.')
    //   return
    // }
    //  else
    if (
      !user?.allowFreeAccess &&
      typeof features.videoMessages != 'string' &&
      user.usage.videoMessages >= Number(features.videoMessages)
    ) {
      console.log('Running of video message check...', user, features)
      onError('You have used your available quota. Upgrade you plan to send more videos.')
      return
    } else {
      console.log('Else run.. video messages')
    }

    // if (
    //   !user?.allowFreeAccess &&
    //   (user.usage.storage >= features.storage || user.usage.videoMessages >= features.videoMessages)
    // ) {
    //   onError('You have used your available quota. Upgrade you plan to send more videos.')
    //   return
    // }

    let sendTo = ''
    console.log('userId:', userId)
    let {notes, contactDetails, opportunityDetails, videoDetails, subject, captions, themeId} =
      payload
    console.log(captions, 'captionscaptions')
    let opportunityId = ''

    if (contactDetails?.contactType != 'existing') {
      let newContactDetails = await firebase
        .firestore()
        .collection('contacts')
        .add({
          name: contactDetails?.firstName + ' ' + contactDetails?.lastName,
          firstName: contactDetails?.firstName,
          lastName: contactDetails?.lastName,
          companyName: contactDetails?.companyName,
          email: contactDetails?.email,
          telephone: contactDetails?.telephone,
          createdAt: firebase.firestore.Timestamp.now(),
          createdById: currentUser.uid,
        })

      sendTo = newContactDetails?.id
    } else {
      sendTo = contactDetails?.sendTo
    }

    if (opportunityDetails.opportunityType == 2) {
      let details = await firebase
        .firestore()
        .collection('opportunity')
        .add({
          opportunityName: opportunityDetails.opportunity,
          instructions: opportunityDetails.instructions,
          opportunityStats: {
            videosSent: 0,
            videosOpened: 0,
            videosNotOpened: 0,
            videosViewed: 0,
            interested: 0,
            notInterested: 0,
          },
          createdAt: firebase.firestore.Timestamp.now(),
          createdById: currentUser.uid,
          opportunityType: 'sendVideo',
        })

      console.log('Details', details)

      opportunityId = details?.id
    } else {
      opportunityId = opportunityDetails?.opportunity
    }

    console.log('userId:', userId)
    let obj: any = {
      sendTo,
      opportunity: opportunityId,
      buttonText: opportunityDetails?.interestedText || '',
      thumbnailType: videoDetails?.thumbnailType || '',
      videoTitle: videoDetails?.videoTitle || '',
      thumbnailUrl: videoDetails?.thumbnailUrl || '',
      videoUrl: videoDetails?.url || '',
      videoSize: videoDetails?.videoSize || 0,
      // fileUrl: '',
      callAction: opportunityDetails?.callAction,
      subject,
    }
    if (videoDetails?.greetingText) {
      obj.greetingText = videoDetails?.greetingText
      obj.greetingDescription = videoDetails?.greetingDescription
    }

    if (videoDetails?.recipientEmail) {
      obj.recipientEmail = videoDetails?.recipientEmail
      obj.bcc = videoDetails?.bcc
    }

    let videoName: string = localStorage.getItem('videoName') || ''

    if (isIOSDevice() && videoName && videoName != '') {
      obj = {
        ...obj,
        videoName,
      }
    }

    if (obj.thumbnailType != 'staticImage') {
      let newUrl = videoDetails?.thumbnailUrl.replace('.mp4', '.gif')
      obj.thumbnailUrl = newUrl
    }

    console.log(opportunityDetails.callActionFile)
    if (opportunityDetails?.callAction == 'fileDownload') {
      if (typeof opportunityDetails?.callActionFile == 'string') {
        obj.fileUrl = opportunityDetails.callActionFile
        obj.fileName = opportunityDetails?.fileName || ''
      } else {
        const imageFile = opportunityDetails.callActionFile
        const fileName = opportunityDetails.callActionFile.name
        const fileExtension = fileName.slice(fileName.lastIndexOf('.'))
        const fileNameWithExtension = currentUser.uid + fileExtension.toLowerCase()
        const storageRef = firebase.storage().ref('action files/' + fileNameWithExtension)
        const uploadTaskSnapshot = await storageRef.put(imageFile)
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL()
        obj.fileUrl = downloadURL
        obj.fileName = fileName
      }
    }

    const newVideoRef = await firebase
      .firestore()
      .collection('recordedVideos')
      .add({
        ...obj,
        count: 0,
        fullyWatched: false,
        partiallyWatched: false,
        isOpened: false,
        recipientReaction: '',
        openedDate: null,
        createdAt: firebase.firestore.Timestamp.now(),
        sendById: currentUser.uid,
        fullyWatchedCount: 0,
        partiallyWatchedCount: 0,
        isOpenedCount: 0,
        captions: captions,
        themeId,
      })
    let parentRef = firebase.firestore().collection('recordedVideos').doc(newVideoRef.id)
    parentRef.collection('notes').add({note: notes, createdAt: firebase.firestore.Timestamp.now()})
    await firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .update({
        'usage.videoMessages': firebase.firestore.FieldValue.increment(1),
        'usage.storage': firebase.firestore.FieldValue.increment(videoDetails?.videoSize || 0),
      })
    await handleOpportunityStats(obj.opportunity, 'videosSent')
    await handleOpportunityStats(obj.opportunity, 'videosNotOpened')
    onSuccess(newVideoRef?.id)
    return newVideoRef?.id
  } catch (error: any) {
    console.error('Error adding user: ', error)
    onError(error?.message)
  }
}

const scheduleVideo = async (
  payload: any,
  onSuccess = (id: string) => {},
  onError = (err: any) => {}
): Promise<string | void> => {
  console.log('sendVidepayload', payload)
  try {
    const currentUser = firebase.auth().currentUser
    const userId = getUserIdToUpdateStats()
    console.log('userId:', userId)
    if (!currentUser) {
      throw new Error('No authenticated user found')
    }
    let auth: any = getAuth()
    let user: any = null
    let priceId = null
    if (auth.role !== 'super-admin') {
      let userData = await getUserById(auth.createdById)
      user = userData
      priceId = userData.priceId
    } else {
      user = auth
      priceId = auth.priceId
    }
    let features: any = await getPricingQuota(priceId || '')
    console.log('Features...', features, user)
    if (!user?.allowFreeAccess && !features) {
      // alert('You have used your available quota. Upgrade you plan to send more videos.')
      onError('You have used your available quota. Upgrade you plan to send more videos.')
      return
    }
    if (!auth) {
      // alert('You are not logged in')
      onError('You are not logged in')

      return
    }

    console.log(
      'Checking the data',
      !user?.allowFreeAccess,
      typeof features.storage != 'string',
      user.usage.storage >= features.storage
    )

    if (
      !user?.isAllowFreeAccess &&
      typeof features.storage == 'string' &&
      !features.storage.toLowerCase().includes('unlimited')
    ) {
      console.log('First run..')

      onError('You have used your available quota. Upgrade you plan to send more videos.')
      return
    } else if (
      !user?.allowFreeAccess &&
      typeof features.storage != 'string' &&
      user.usage.storage >= features.storage
    ) {
      console.log('Storage full')
      onError('You have used your available quota. Upgrade you plan to send more videos.')
      return
    } else {
      console.log('Else run...of send video')
    }

    // if (
    //   !user?.isAllowFreeAccess &&
    //   typeof features.videoMessages == 'string' &&
    //   !features.videoMessages.toLowerCase().includes('unlimited')
    // ) {
    //   onError('You have used your available quota. Upgrade you plan to send more videos.')
    //   return
    // } else
    if (
      !user?.allowFreeAccess &&
      typeof features.videoMessages != 'string' &&
      user.usage.videoMessages >= Number(features.videoMessages)
    ) {
      console.log('Running of video message check...', user, features)
      onError('You have used your available quota. Upgrade you plan to send more videos.')
      return
    } else {
      console.log('Else run.. video messages')
    }

    let sendTo = ''
    console.log('userId:', userId)
    let {
      notes,
      themeId,
      contactDetails,
      opportunityDetails,
      videoDetails,
      subject,
      captions,
      sendVideoData,
      scheduleTime,
    } = payload
    let opportunityId = ''

    if (contactDetails?.contactType != 'existing') {
      let newContactDetails = await firebase
        .firestore()
        .collection('contacts')
        .add({
          name: contactDetails?.firstName + ' ' + contactDetails?.lastName,
          firstName: contactDetails?.firstName,
          lastName: contactDetails?.lastName,
          companyName: contactDetails?.companyName,
          email: contactDetails?.email,
          telephone: contactDetails?.telephone,
          createdAt: firebase.firestore.Timestamp.now(),
          createdById: currentUser.uid,
        })

      sendTo = newContactDetails?.id
    } else {
      sendTo = contactDetails?.sendTo
    }

    if (opportunityDetails.opportunityType == 2) {
      let details = await firebase
        .firestore()
        .collection('opportunity')
        .add({
          opportunityName: opportunityDetails.opportunity,
          instructions: opportunityDetails.instructions,
          opportunityStats: {
            videosSent: 0,
            videosOpened: 0,
            videosNotOpened: 0,
            videosViewed: 0,
            interested: 0,
            notInterested: 0,
          },
          createdAt: firebase.firestore.Timestamp.now(),
          createdById: currentUser.uid,
          opportunityType: 'sendVideo',
        })

      console.log('Details', details)

      opportunityId = details?.id
    } else {
      opportunityId = opportunityDetails?.opportunity
    }

    console.log('userId:', userId)
    let obj: any = {
      sendTo,
      opportunity: opportunityId,
      buttonText: opportunityDetails?.interestedText || '',
      thumbnailType: videoDetails?.thumbnailType || '',
      videoTitle: videoDetails?.videoTitle || '',
      thumbnailUrl: videoDetails?.thumbnailUrl || '',
      videoUrl: videoDetails?.url || '',
      videoSize: videoDetails?.videoSize || 0,
      // fileUrl: '',
      callAction: opportunityDetails?.callAction,
      subject,
    }
    if (videoDetails?.greetingText) {
      obj.greetingText = videoDetails?.greetingText
      obj.greetingDescription = videoDetails?.greetingDescription
    }

    if (videoDetails?.recipientEmail) {
      obj.recipientEmail = videoDetails?.recipientEmail
      obj.bcc = videoDetails?.bcc
    }

    let videoName: string = localStorage.getItem('videoName') || ''

    if (isIOSDevice() && videoName && videoName != '') {
      obj = {
        ...obj,
        videoName,
      }
    }

    if (obj.thumbnailType != 'staticImage') {
      let newUrl = videoDetails?.thumbnailUrl.replace('.mp4', '.gif')
      obj.thumbnailUrl = newUrl
    }

    console.log(opportunityDetails.callActionFile)
    if (opportunityDetails?.callAction == 'fileDownload') {
      if (typeof opportunityDetails?.callActionFile == 'string') {
        obj.fileUrl = opportunityDetails.callActionFile
        obj.fileName = opportunityDetails?.fileName || ''
      } else {
        const imageFile = opportunityDetails.callActionFile
        const fileName = opportunityDetails.callActionFile.name
        const fileExtension = fileName.slice(fileName.lastIndexOf('.'))
        const fileNameWithExtension = currentUser.uid + fileExtension.toLowerCase()
        const storageRef = firebase.storage().ref('action files/' + fileNameWithExtension)
        const uploadTaskSnapshot = await storageRef.put(imageFile)
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL()
        obj.fileUrl = downloadURL
        obj.fileName = fileName
      }
    }

    const newVideoRef = await firebase
      .firestore()
      .collection('scheduledVideos')
      .add({
        ...obj,
        themeId,
        count: 0,
        fullyWatched: false,
        partiallyWatched: false,
        isOpened: false,
        recipientReaction: '',
        openedDate: null,
        createdAt: firebase.firestore.Timestamp.now(),
        sendById: currentUser.uid,
        fullyWatchedCount: 0,
        partiallyWatchedCount: 0,
        isOpenedCount: 0,
        captions: captions,
        sendVideoData,
        scheduleTime,
      })
    let parentRef = firebase.firestore().collection('scheduledVideos').doc(newVideoRef.id)
    parentRef.collection('notes').add({note: notes, createdAt: firebase.firestore.Timestamp.now()})
    await firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .update({
        'usage.videoMessages': firebase.firestore.FieldValue.increment(1),
        'usage.storage': firebase.firestore.FieldValue.increment(videoDetails?.videoSize || 0),
      })
    await handleOpportunityStats(obj.opportunity, 'videosSent')
    await handleOpportunityStats(obj.opportunity, 'videosNotOpened')
    onSuccess(newVideoRef?.id)
    return newVideoRef?.id
  } catch (error: any) {
    console.error('Error adding user: ', error)
    onError(error?.message)
  }
}

const getRequestedDataForRecordVideo = async (id: any) => {
  try {
    const docRef = firebase.firestore().collection('requestVideo').doc(id)
    const docSnapshot = await docRef.get()

    if (!docSnapshot.exists) {
      throw new Error('Request on this opportunity is not found for the given userName')
    }

    const data = docSnapshot.data()

    let senderDetails: any = await firebase
      .firestore()
      .collection('users')
      .doc(data?.requestedById)
      .get()
    const response = {
      ...data,
      id: docSnapshot.id,
      senderDetails: {id: senderDetails?.id, ...senderDetails.data()},
    }

    return response
  } catch (error) {
    console.error('Error getting request video: ', error)
    // Handle the error as needed.
  }
}

const sendVideoAgainstRequest = async (
  data: any,
  creatorDetails: any,
  onSuccess = (id: string) => {},
  onError = (err: any) => {}
) => {
  try {
    let features: any = await getPricingQuota(creatorDetails?.priceId || '')
    if (!creatorDetails?.allowFreeAccess && !features) {
      // alert('You have used your available quota. Upgrade you plan to send more videos.')

      onError('You have used your available quota. Upgrade you plan to send more videos.1')
      return
    }

    // if (
    //   !creatorDetails?.allowFreeAccess &&
    //   typeof features.users == 'string' &&
    //   !features.users.toLowerCase().includes('unlimited')
    // ) {
    //   onError('You have used your available quota. Upgrade you plan to send more videos.2')
    //   return
    // } else
    if (
      !creatorDetails?.allowFreeAccess &&
      typeof features.users != 'string' &&
      creatorDetails.usage.users >= features.users
    ) {
      // alert('You have used your available quota. Upgrade you plan to send more videos.')
      onError('You have used your available quota. Upgrade you plan to send more videos.3')
      return
    }

    const response: any = await firebase
      .firestore()
      .collection('recordedVideos')
      .add({
        ...data,
        count: 0,
        fullyWatched: false,
        partiallyWatched: false,
        isOpened: false,
        openedDate: null,
        createdAt: firebase.firestore.Timestamp.now(),
        fullyWatchedCount: 0,
        partiallyWatchedCount: 0,
        isOpenedCount: 0,
        // captions: captions.url !=undefined? null : captions,
        // captions: captions ? captions : null,
      })
    await firebase
      .firestore()
      .collection('users')
      .doc(creatorDetails?.id)
      .update({
        'usage.videoMessages': firebase.firestore.FieldValue.increment(1),
        'usage.storage': firebase.firestore.FieldValue.increment(data?.videoSize || 0),
      })
    handleOpportunityStats(data.opportunity, 'videosReceived')

    console.log('Send video success', response)
    await updateRequestVideo({id: data.requestId, status: 'recorded'})
    onSuccess(response?.id)
    return response
  } catch (error: any) {
    onError(error?.message)
    console.log('Send video error', error)
    return error
  }
}

const getRecordedVideoById: any = async (id: ID, onSuccess = () => {}, onError = () => {}) => {
  try {
    const videoRef = firebase.firestore().collection('recordedVideos').doc(id)
    const videoSnapshot = await videoRef.get()
    if (!videoSnapshot.exists) {
      throw new Error('Video not found')
    }

    let notes: any = []
    const notesSnapshot = await videoRef.collection('notes').get()
    if (!notesSnapshot.empty) {
      notes = notesSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
    }

    const data = {
      id: videoSnapshot.id,
      notes,
      ...videoSnapshot.data(),
    }
    onSuccess()
    return data
  } catch (error) {
    onError()
    console.error('Error getting Recorded video: ', error)
  }
}

const deleteRecordedVideo = async (documentId: ID, onSuccess = () => {}, onError = () => {}) => {
  try {
    let userId = getUserIdToUpdateStats()
    const notesCollection = await firebase
      .firestore()
      .collection('recordedVideos')
      .doc(documentId)
      .collection('notes')
      .get()

    notesCollection.forEach(async (doc) => {
      await firebase
        .firestore()
        .collection('recordedVideos')
        .doc(documentId)
        .collection('notes')
        .doc(doc.id)
        .delete()
    })
    await firebase.firestore().collection('recordedVideos').doc(documentId).delete()
    await firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .update({
        'usage.videoMessages': firebase.firestore.FieldValue.increment(-1),
        'usage.storage': firebase.firestore.FieldValue.increment(-0.1),
      })
    console.log('Document deleted successfully!')
    onSuccess()
  } catch (error) {
    console.error('Error deleting document: ', error)
    onError()
  }
}

const handleVideoStats = async (docId: string, fieldName: string) => {
  try {
    const userRef = firebase.firestore().collection('recordedVideos').doc(docId)
    if (fieldName === 'openedDate') {
      await userRef.update({
        [fieldName]: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
      })
    } else if (
      fieldName === 'interested' ||
      fieldName === 'not-interested' ||
      fieldName === 'info-provided' ||
      fieldName === 'download-file'
    ) {
      await userRef.update({
        recipientReaction: fieldName,
        updatedAt: firebase.firestore.Timestamp.now(),
      })
    } else {
      const incrementField = `${fieldName}Count`

      await userRef.update({
        [fieldName]: true,
        [incrementField]: firebase.firestore.FieldValue.increment(1),
        updatedAt: firebase.firestore.Timestamp.now(),
      })
    }
  } catch (error) {
    console.error('Error In updating opportunity: ', error)
  }
}

const handleOpportunityStats = async (docId: string, fieldName: string) => {
  console.log('field Name', fieldName)
  try {
    const opportunityDoc = await firebase.firestore().collection('opportunity').doc(docId).get()
    const opportunityData: any = opportunityDoc.data()
    opportunityData.opportunityStats[fieldName]++
    await firebase.firestore().collection('opportunity').doc(docId).update({
      opportunityStats: opportunityData.opportunityStats,
    })
    console.log('Updated opportunity stats inc')
  } catch (error) {
    console.log(error)
  }
}
const handleOpportunityStatsDecrement = async (docId: string, fieldName: string) => {
  try {
    const opportunityDoc = await firebase.firestore().collection('opportunity').doc(docId).get()
    const opportunityData: any = opportunityDoc.data()
    console.log('abc', opportunityData.opportunityStats)
    opportunityData.opportunityStats[fieldName]--
    await firebase.firestore().collection('opportunity').doc(docId).update({
      opportunityStats: opportunityData.opportunityStats,
    })
    console.log('Updated opportunity stats dec')
  } catch (error) {
    console.log(error)
  }
}
const handleBothOpportunityStats = async (docId: string, inc: string, dec: string) => {
  try {
    const opportunityDoc = await firebase.firestore().collection('opportunity').doc(docId).get()
    const opportunityData: any = opportunityDoc.data()
    opportunityData.opportunityStats[inc]++
    opportunityData.opportunityStats[dec]--
    await firebase.firestore().collection('opportunity').doc(docId).update({
      opportunityStats: opportunityData.opportunityStats,
    })
    console.log('Updated opportunity stats dec')
  } catch (error) {
    console.log(error)
  }
}

const addNotesInRecordedVideo = async (id: string, note: string) => {
  try {
    const parentCollectionRef = firebase.firestore().collection('recordedVideos').doc(id)
    await parentCollectionRef.collection('notes').add({
      note,
      createdAt: firebase.firestore.Timestamp.now(),
    })
  } catch (error) {
    console.log(error)
  }
}

const updateRecordedVideo = async (id: any, data: any) => {
  try {
    const collectionRef = firebase.firestore().collection('recordedVideos').doc(id)
    await collectionRef.set(
      {requestCallNumber: data, updatedAt: firebase.firestore.Timestamp.now()},
      {merge: true}
    )
    return 'success'
  } catch (error) {
    console.log(error)
  }
}
const updateRecordedVideoSubject = async (
  id: string,
  data: any,
  onSuccess = () => {},
  onError = (err: any) => {}
) => {
  try {
    const collectionRef = firebase.firestore().collection('recordedVideos').doc(id)
    await collectionRef.set(
      {
        ...data,
        updatedAt: firebase.firestore.Timestamp.now(),
      },
      {merge: true}
    )
    onSuccess()
    // return 'success'
  } catch (error: any) {
    // console.log(error)
    onError(error?.message)
  }
}

const checkVideoAlreadySent = async (data: any, onSuccess = () => {}, onError = () => {}) => {
  console.log('checkVideoAlreeadSent', data)
  let isAlreadyFound = await firebase
    .firestore()
    .collection('recordedVideos')
    .where('sendById', '==', data?.sendTo)
    .where('videoName', '==', data?.videoName)
    .get()

  if (isAlreadyFound.size > 0) {
    onError()
  } else {
    onSuccess()
  }
}

export {
  addNotesInRecordedVideo,
  createOpportunity,
  deleteOpportunity,
  deleteRecordedVideo,
  deleteSelectedOpportunities,
  getOpportunities,
  getOpportunityById,
  getRecordedVideoById,
  getRecordedVideos,
  getRecordedVideosOnOneOpportunity,
  getRequestedDataForRecordVideo,
  handleBothOpportunityStats,
  handleOpportunityStats,
  handleOpportunityStatsDecrement,
  handleVideoStats,
  requestVideo,
  sendEmail,
  sendVideo,
  scheduleVideo,
  sendVideoAgainstRequest,
  updateOpportunity,
  updateRecordedVideo,
  updateRecordedVideoSubject,
  checkVideoAlreadySent,
}
